/**
 * IMPORTS
 */

import { extendTheme } from '@chakra-ui/react';

/**
 * CORE
 */

// @see https://smart-swatch.netlify.app/ (get JS object)
const colors = {
  brand: {
    50: '#ffe5f2',
    100: '#f9bcd4',
    200: '#ee91b6',
    300: '#e66699',
    400: '#dd3b7b',
    500: '#c42262',
    600: '#99184c',
    700: '#6f1036',
    800: '#440621',
    900: '#1d000c',
  },
};

const fonts = {
  body: '"Open Sans",Arial,sans-serif',
  heading: 'Oswald,Helvetica,Arial,sans-serif',
};

const Button = {
  baseStyle: {
    borderRadius: 'full',
  },
};

const Heading = {
  baseStyle: {
    color: 'brand.500',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Heading,
  },
});

export default theme;
